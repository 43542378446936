
$general_sans_400: 'General Sans Regular', sans-serif;
$general_sans_400_italic: 'General Sans Italic', sans-serif;
$general_sans_200: 'General Sans Extralight', sans-serif;
$general_sans_200_italic: 'General Sans Extralight Italic', sans-serif;
$general_sans_300: 'General Sans Light', sans-serif;
$general_sans_300_italic: 'General Sans Light Italic', sans-serif;
$general_sans_500: 'General Sans Medium', sans-serif;
$general_sans_500_italic: 'General Sans Medium Italic', sans-serif;
$general_sans_600: 'General Sans Semibold', sans-serif;
$general_sans_600_italic: 'General Sans Semibold Italic', sans-serif;
$general_sans_700: 'General Sans Bold', sans-serif;
$general_sans_700_italic: 'General Sans Bold Italic', sans-serif;


body {
	background-color: #f3f3f3;
}

h2 {
	font-size: 24px;
	font-family: $general_sans_600 !important;
}

.party-pill {
	padding: 4px 8px;
	background-color: #fb9905;
	border-radius: 16px;
	font-size: 10px;
	font-weight: 600;
	color: #000;
	display: inline-block;

	&.all-parties {
		background-color: #000;
		color: #fff;
	}
}

.dropdown-toggle .party-pill {
	padding: 2px 6px;
}


.card-alert {
	display: inline-block;
	background-color: #000;
	border-radius: 50%;
	width: 20px;
	height: 20px; 
	position: relative;
	top: 5px;
	left: 5px;
	cursor: pointer;

	.alert {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		color:#fff;
	}
}

.dashboard-nav {
	position: sticky;
	top: 0;
	background-color: #f3f3f3;
	z-index: 999;
}

.nav-button {
	display: inline-block;
	background-color: #e0e0e0;
	color: #000;
	font-family: $general_sans_500 !important;
	border-radius: 16px;
	text-decoration: none;
	padding: 10px 15px;
	margin: 0 0.5em 0 0;
	font-size: 14px;
}

.nav-button-direction {
	display: inline-block;
	background-color: #e0e0e0;
	color: #000;
	border-radius: 50%;
	text-decoration: none;
	padding: 10px 15px;
	margin: 0 0 0 0.5em;
	font-size: 14px;
	cursor: pointer;

	&:hover {
		background-color: #000;
		color: #fff;
	}
}

.scroll-area {
	position: relative;
}

.scroll-area::after {
	content: "";
	display: block;
	position: absolute;
	width: 100%;
	bottom: 0;
	left: 0;
	height: 20px;
	background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.06) 100%);
}

.dropdown-select {
	font-family: $general_sans_500 !important;
}



.form-label {
	font-size: 12px;
	font-family: $general_sans_500 !important;
}

.card-help {
	position: absolute;
	height: 24px;
	width: 24px;
	top: -12px;
	right: -12px;
	color: #fff;
	padding: 5px;
	border-radius: 8px;
	background-color: #000;
	transition: all .5s;
	cursor: pointer;

	.question {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		color: #fff;
		font-size: 12px;
		font-weight: 600;
	}

	.card-help-content {
		display: none;
	}

	&:hover {
		transform: scale(1.1);
	}
}

.toggleButtonGroup {
	border-radius: 20px;
	background-color: #dbdbdb;
	white-space: nowrap;

	button {
		border: none;
		width: Fixed (100px) px;
		height: Fill (36px) px;
		padding: 12px 20px 12px 20px;
		gap: 10px;
		border-radius: 35px;
		display: inline-block;
		background-color: #dbdbdb;

		&.active {
			background-color: #000000;
			color: #ffffff;
		}
	}
}

.date-picker input {
	padding: 1em 1.2em;
	border-radius: 35px;
	border: none;
	width: 100%;
	text-align: left;
	background-color: rgba(0, 0, 0, 0.05);
	color: #000;
	font-size: 1rem;
	cursor: pointer;
}



.date-picker.size-sm input {
	padding: 0.25em 0.65em;
}

.date-picker.width-auto input {
	width: auto;
}

.section-header {
	height: 170px;
	background-color: #e7e7e7;
	border-radius: 18px;
	overflow: hidden;
}

.header-img-container {
	height: 170px;
	overflow: hidden;
	background-color: #FB9905;
	position: relative;
	border-radius: 18px;

	h2 {
		position: absolute;
		top: 50%;
		left: 5%;
		transform: translateY(-50%);
		color: #fff;
	}
}


.header-img {
	
	background-size: cover;
	background-position: center;
	height: 170px;
	filter: grayscale(100%);
	mix-blend-mode: multiply;
	
}

.section-intro {
	font-family: $general_sans_500 !important;
	padding: 0 3em 0 2em;
}

.card-bar {
    background-color: #ee9b8a;
    border-radius: 2px;
    position: relative;
    height: 4px;
    transform: translateY(100%);
}

.card-bar-fill {
    background-color: #b7eb9f;
    position: absolute;
    left: 0;
    top: 0;
    height: 4px;
    border-radius: 2px;
}

.card-bar-mark {
    position: absolute;
    height: 8;
    top: 50%;
    transform: translateY(-50%);
    width: 1px;
    background-color: #000;
}


.current-party td {
	background-color: #fbf5ec;
	border-color: #fbd9a7
}

thead {
	font-size: 12px;
	font-family: $general_sans_500 !important;

	th {
		font-weight: 500;
	}
}

tbody {
	font-size: 12px;
	font-family: $general_sans_400 !important;
}


.dashboard-card {
    background-color: #fff;
    border-radius: 16px;
    padding: 16px;
    position: relative;
    height: 100%;
}

.faded {
    opacity: 0.3;
}

h3 {
    font-family: $general_sans_500 !important;
    font-size: 16px;
    margin: 0;
}

h4 {
    font-size: 14px;
    font-family: $general_sans_500 !important;
}



.card-big-text {
    font-size: 24px;
    font-family: $general_sans_500 !important;
    display: inline-block;
}

.card-subtext {
    font-size: 18px;
    margin: 0 0 0 0.8em;
    display: inline-block;
}

.card-badge {
    background-color: rgba(0,0,0,0.1);
    padding: 5px;
    font-size: 11px;
    font-weight: 400;
    display: inline-block;
    border-radius: 5px;
    
}

.party_member_badge {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #000;
    display: inline-block;
    margin: 0 10px 0 0;
    background-size: cover;
}

.seperator {
    border-top: 1px solid #eeeeee;
}


// CHARTS

.attendance-breakdown {
	height: 120px;
 }
 
 .attendance-breakdown.all-parties {
	 height: 20px;
	 margin-top: 1em;
 }
 
 .attendance-category {
	 display: inline-block;
	 height: 100%;
 }
 
 
 .attendance-block {
	 position: relative;
	 height: 100%;
	 display: inline-block;
 }
 
 .attendance-block-inner {
	 position: absolute;
	 bottom: -13px;
	 width: 120px;
	 transform: rotate(-90deg);
	 transform-origin: 0 0;
	 display: flex;
 
 
	 .attendance-label {
		 width: 50%;
		 text-align: center;
		 text-wrap: nowrap;
		 overflow: hidden;
		 text-overflow: ellipsis;
		 padding-right:10px;
		 font-size: 12px;
	 }
 
	 .attendance-count {
		 width: 50%;
		 text-align: left;
	 }
 
 
 }
 
 .all-parties .attendance-block-inner {
	 display: none;
 }
 
 .attendance-attended {
	 background-color: #b7eb9f;
 }
 
 .attendance-arrived-late {
	 background-color: #ad7aed;
 }
 
 .attendance-arrived-late-departed-early {
	 background-color: #76a8f2;
 }
 
 .attendance-departed-early {
	 background-color: #77efc5;
 }
 
 .attendance-absent-with-apologies {
	 background-color: #ffc794;
 }
 
 .attendance-absent {
	 background-color: #ffa694;
 }
 
 .attendance-separator {
	 position: absolute;
	 right: 0;
	 height: 145px;
	 bottom: 0;
	 border-right: 1px solid #000;
	 width: 0;
 
	 .attendance-separator-arrow {
		 position: absolute;
		 top: 0;
		 left: 0;
		 transform: translate(-50%, -60%);
	 }
 
	 .attendance-separator-present {
		 position: absolute;
		 top: 0;
		 left: 0;
		 transform: translateX(-110%);
		 white-space: nowrap;
	 }
 
	 .attendance-separator-absent {
		 position: absolute;
		 top: 0;
		 left: 0;
		 transform: translateX(10%);
		 white-space: nowrap;
	 }
 }
 
 .all-parties .attendance-separator {
	 height: 20px;
	 top: 0;
	 bottom: unset;
 
	 .attendance-separator-arrow {
		 top: unset;
		 bottom: 0;
		 transform: translate(-50%,60%);
	 }
 
	 .attendance-separator-present {
		 display: none;
	 }
 
	 .attendance-separator-absent {
		 display: none;
	 }
 }
 
 .chart-legend {
	 font-size: 12px;
 
	 .legend-item {
		 display: inline-block;
		 margin-right: 10px;
 
		 .legend-color {
			 width: 12px;
			 height: 0px;
			 position:relative;
			 top: -3px;
			 border-top: 2px solid;
			 display: inline-block;
 
			 &.legend-circle {
				 border-radius: 50%;
				 border: 2px solid;
				 width: 10px;
				 height: 10px;
				 top: 0;
			 }
		 }
 
		 .legend-label {
			 display: inline-block;
			 margin-left: 5px;
			 color: #797979;
			 font-family: $general_sans_500 !important;
		 }
	 }
 }
 
 .chart-tooltip {
	 position: absolute;
	 background-color: #fbf5ec !important;
	 border-radius: 5px;
	 color: #000;
	 padding: 10px !important;
	 font-size: 12px;
	 line-height: 18px !important;
	 max-width: 180px;
	 white-space: nowrap;
	 z-index: 9999;
 }

.modal-header {
	border-bottom: none; 
}

.modal-body {

	padding: 1em 2em;

	h2 {
		font-size: 18px;
	}

	input {
		background-color: #d9d9d9;
	}

	button {
		background-color: #000;
		color: #fff;
		padding: 10px 25px;
		border-radius: 30px;
		font-family: $general_sans_500 !important;

		&:hover {
			background-color: #fff;
			color: #000;
		}
	}
}

.rs-picker-popup {
	z-index: 1000 !important;

	.rs-calendar-header-error {
		color: #000;
		--rs-btn-subtle-text: #000;
		--rs-btn-subtle-hover-bg: #000;
		--rs-btn-subtle-hover-text: #fff;
		--rs-btn-subtle-active-bg: #000;
		--rs-btn-subtle-active-text: #fff;
	}

	.rs-btn-primary:disabled, .rs-btn-primary.rs-btn-disabled, .rs-btn-primary {
		background-color: #000;
		color: #fff;
	}

	.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
		color: #fff;
		background-color: #fb9905;
		-webkit-box-shadow: none;
		box-shadow: none;
	}

	.rs-calendar-table-cell-in-range:before {
		background-color: rgba(0,0,0,0.1);
	}
}

.rs-input-group.rs-input-group-inside, .rs-input {
	background-color: #e7e7e7 !important;
	color: #000;
	border-radius: 30px !important;
	padding: 1px 0;
}

.rs-calendar-table-cell.rs-calendar-table-cell-disabled {
	opacity: 0.2;
}


 
 

